/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


/* Bootstrap grid */
@import "assets/css/bootstrap-grid.css";
@import "assets/css/bootstrap-utilities.css";

/* Root */
:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;

  --fontsize: 14px;
	--fontsizeHeading1: 3em;
	--fontsizeHeading2: 2.5em;
	--fontsizeHeading3: 2em;
	--fontsizeHeading4: 1.5em;
	--fontsizeHeading5: 1.2em;
	--fontsizeHeading6: 1em;
	--fontsizeParagraph: 1em;
	--fontsizeParagraphsmall: 0.9em;
	--fontsizeParagraphxsmall: 0.8em;
	--fontsizeParagraphxxsmall: 0.7em;

  // Normalize font
  ion-slide,
  ion-item,
  ion-button,
  ion-back-button,
  ion-buttons,
  ion-tab-button,
  ion-card,
  ion-title,
  ion-segment-button {
    font-size: var(--fontsize) !important;
  }
}

ion-header {
  ion-toolbar {
    --min-height: calc(40px + 6px + 0.75rem);
  }
}

ion-list {
  background: none !important;
  ion-item {
    --background: none !important;
  }
}

ion-tab-button {
  ion-icon {
    font-size: 1.5em;
  }
}

ion-back-button {
  --color: #fff;
  --icon-font-size: 1.5em;
  --padding-start: 0.5em;
  --padding-end: 0.5em;
  --icon-margin-end: 0.25em;
  --icon-margin-start: 0;
}

ion-button {
  &.btn-small {
    min-width: 100px;
  }
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

html,
body {
  min-width: 0 !important;
}

body {
  font-size: var(--fontsize) !important;
  line-height: 1.5;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
  line-height: 1.5;
}

h1,
.h1 {
  font-size: var(--fontsizeHeading1) !important;
}

h2,
.h2 {
  font-size: var(--fontsizeHeading2) !important;
}

h3,
.h3 {
  font-size: var(--fontsizeHeading3) !important;
}

h4,
.h4 {
  font-size: var(--fontsizeHeading4) !important;
}

h5,
.h5 {
  font-size: var(--fontsizeHeading5) !important;
}

h6,
.h6 {
  font-size: var(--fontsizeHeading6) !important;
}

p,
.p {
  font-size: var(--fontsizeParagraph) !important;

  p {
    margin: 0;
  }
}

.psmall {
  font-size: var(--fontsizeParagraphsmall) !important;
}

.pxsmall {
  font-size: var(--fontsizeParagraphxsmall) !important;
}

.pxxsmall {
  font-size: var(--fontsizeParagraphxxsmall) !important;
}

a {
  text-decoration: none;
}

hr {
  background: rgba(0,0,0,0.2);
  margin: 1em 0;
}

.bg-img {
  --background: transparent url('/assets/media/bg.jpg') no-repeat center center / cover;
}

.logo {
  height: 40px;
  display: block;
}
.profile {
  margin-left: auto;
}

.avatar {
  background-color: var(--ion-color-primary);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-size: var(--fontsizeParagraphsmall);
  cursor: pointer;
  &.sm {
    width: 32px;
    height: 32px;
    flex: 0 0 32px;
  }
  &.md {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
  }
  &.lg {
    width: 64px;
    height: 64px;
    flex: 0 0 64px;
  }
  &.light {
    background-color: var(--ion-color-light);
    color: #000;
  }
}

.card {
  background: #fff;
  padding: 1em;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 1em;
  overflow: hidden;
  .card-header {
    margin: -1em -1em 1em;
    padding: 0.5em 1em;
    color: #fff;
    font-weight: bold;
    &.approved {
      background: var(--ion-color-success);
    }
    &.waiting {
      background: var(--ion-color-warning);
    }
    &.rejected {
      background: var(--ion-color-danger);
    }
  }
}

.schedules {
  display: flex;
  color: #fff;
  margin-bottom: 1em;
  .schedule {
    flex: 0 0 60px;
    padding: 0.5em 1em;
    text-align: center;
    border: solid 1px #fff;
    border-radius: 1em;
    margin-right: 1em;
    &.active {
      background: #fff;
      color: var(--ion-color-primary);
    }
  }
}

.list-menu {
  margin: 0 -1em;
  border-top: solid 1px rgba(0,0,0,0.1);
}

.proj-radio {
  position: absolute;
  left: -9999px;
}
.proj-radio + label {
  position: relative;
  cursor: pointer;
  line-height: 1.5;
  display: inline-block;
  font-size: var(--fontsizeParagraph);
  width: 100%;
  padding: 1em;
  border-radius: 8px;
  color: var(--ion-color-medium);
  background: var(--ion-color-light);
}
.proj-radio:checked + label {
  color: var(--ion-color-light);
  background: var(--ion-color-primary);
}
.proj-radio.small + label {
  padding: 0.5em;
  font-size: ar(--fontsizeParagraphsmall);
}

.proj-radio-bullet {
  position: absolute;
  left: -9999px;
}
.proj-radio-bullet + label {
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-size: var(--fontsizeParagraphsmall);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
	margin: 0;
}
.proj-radio-bullet + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: flex;
  font-size: var(--fontsizeParagraph);
  width: 100%;
}
.proj-radio-bullet + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
  box-sizing: border-box;
}
.proj-radio-bullet + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: var(--ion-color-primary);
  position: absolute;
  top: 50%;
  margin-top: -6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  box-sizing: border-box;
}
.proj-radio-bullet:checked + label:before {
  border-color: var(--ion-color-primary);
}
.proj-radio-bullet:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.select {
  width: 100%;
  position: relative;
  select {
    position: relative;
    height: 41px;
    line-height: 41px;
    margin: 0;
    padding: 0;
    padding-right: 18px;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    font-size: var(--fontsize);
    -webkit-appearance: none;
       -moz-appearance: none;
  }
  ion-icon {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -9px;
    width: 18px;
    height: 18px;
    z-index: -1;
    font-size: 0.5em;
  }
}


.alertmodal {
  --border-radius: 1em;
	--backdrop-opacity: 0.5;
	--width: 320px;
	--height: 200px;
}

.smallmodal {
  --border-radius: 1em;
	--backdrop-opacity: 0.5;
	--width: 95%;
	--height:460px;
}
